<template>
  <div data-app>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="6" md="6" style="color: #e33354" class="ml-4">
            <h3>{{ cardTitle }}</h3>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" sm="3" md="3">
            <v-text-field
              v-model="search"
              append-icon="search"
              :label="$t('FORMS.search')"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="externalReferenceCollection"
          :search="search"
          :sort-by="['id']"
          sort-desc="true"
          :loading="loadingTable"
          :footer-props="getDatatableFooterProps()"
          :items-per-page="getDatatableItemPerPage()"
          @click:row="handleClickItem"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <ExternalReferenceModalForm
                :modalData="modalData"
                :permissions="permissions"
                :statuses="statuses"
                :endPoint="endPoint"
                @closeModalForm="handleCloseModalForm"
                @saveModalForm="handleSaveModalForm"
                @new="handleNew"
              ></ExternalReferenceModalForm>
              <v-spacer></v-spacer>
              <DeleteModalDialog
                :dialogDelete="dialogDelete"
                @closeDelete="handleCloseDelete"
                @deleteItemConfirm="handleDeleteItemConfirm"
              >
              </DeleteModalDialog>
            </v-toolbar>
          </template>
          <template v-slot:item.translated="{ item }">
            <v-icon v-if="item.translated" color="green" small
              >check_circle</v-icon
            >
          </template>
          <!-- <template v-slot:item.status="{ item }">
          <span v-if="statuses.system">{{
            statuses.companySettings.department[item.status].value
          }}</span>
        </template> -->
          <template v-slot:item.actions="{ item }">
            <v-icon
              color="primary"
              v-if="permissionCan('delete')"
              small
              @click="deleteItem(item)"
            >
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import SiteService from "@/core/services/site.service.js";

import { listModelsMixins } from "@/view/mixins/listModelsMixins.js";

import ExternalReferenceModalForm, { initialFormData } from "./ExternalReferenceModalForm";

import DeleteModalDialog from "@/view/components/DeleteModalDialog";

export const PERMISSION_TO = "external_references.category.";

export default {
  name: "ExternalReferences",
  components: { ExternalReferenceModalForm, DeleteModalDialog },
  mixins: [listModelsMixins],
  data() {
    return {
      cardTitle: this.$t("MENU.EXTERNAL_REFERENCES"),
      search: "",
      routePath: "/cms/externalReferences/",
      permissionTo: PERMISSION_TO,
      permissions: JSON.parse(localStorage.permissions),
      headers: [
        {
          text: this.$t("TABLE.translated"),
          value: "translated",
          width: "100px",
        },
        {
          text: this.$t("TABLE.name"),
          value: "name",
        },
        // { text: "Status", value: "status" },
        {
          text: this.$t("TABLE.actions"),
          value: "actions",
          sortable: false,
          width: "100px",
        },
      ],

      modalData: {
        dialog: false,
        editedId: null,
      },
      editedItem: Object.assign({}, initialFormData()),

      dialogDelete: false,
    };
  },

  computed: {
    ...mapGetters(["externalReferenceCollection", "statuses"]),

    endPoint() {
      return "externalReferences/admin/" + SiteService.getActiveSiteId() + "/category/";
    },
  },

  watch: {
    dialogDelete(val) {
      val || this.handleCloseDelete();
    },
    "$route.params.id": {
      handler: function (id) {
        if (id) {
          this.editItem(id);
        }
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    ...mapActions(["fetchExternalReference"]),
    fetchModel() {
      return this.fetchExternalReference();
    },
  },

  mounted() {
    this.loadingTable = true;
    this.fetchModel().then(() => (this.loadingTable = false));
  },
};
</script>

